import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/reviewPage.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ReviewHeader = makeShortcode("ReviewHeader");
const ReviewFooter = makeShortcode("ReviewFooter");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ReviewHeader data={props.pageContext.frontmatter} mdxType="ReviewHeader" />
    <h1>{`Overall`}</h1>
    <p>{`It was something different. Go here for a unique, out of the way poke adventure.
Unlike other haunts, this spot offers a traditional Hawaiian poke experience. You
choose two fish and one topping on a bed of rice, lettuce, or both. The best bit is,
the fish/sauce creations are different each day.`}</p>
    <h1>{`Key Points:`}</h1>
    <ul>
      <li parentName="ul">{`No ginger 😱`}</li>
      <li parentName="ul">{`Compostable takeout box`}</li>
      <li parentName="ul">{`Taste the rainbow (you get to sample all the fish like at Ben & Jerry's)`}</li>
      <li parentName="ul">{`You will want to make friends with the staff`}</li>
      <li parentName="ul">{`Mac & Cheese was a topping option... if you're into that`}</li>
      <li parentName="ul">{`Lists all the ingredients in each sauce for all the high maintenance folk.`}</li>
      <li parentName="ul">{`Traditional Hawaiian poke`}</li>
    </ul>
    <h1>{`Biggest Suggestion`}</h1>
    <p>{`Get yourself some pickled ginger! Jeesh.`}</p>
    <ReviewFooter data={props.pageContext.frontmatter} mdxType="ReviewFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      